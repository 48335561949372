import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Bottom from '../Bottom';
import { APIBaseUrl, WXAppID } from '../../Global';

import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MessageIcon from '@material-ui/icons/Message';
import IconButton from '@material-ui/core/IconButton';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LocalPlayIcon from '@material-ui/icons/LocalPlay';
import StorefrontIcon from '@material-ui/icons/Storefront';

import { setWinXinLoginMsg, logout } from '../../Redux/Action'

import { Get, Post } from '../../Request';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    list: {
        paddingTop: 168 + 8,
        paddingBottom: 56 + 8,
        '& .MuiListItem-root': {
            backgroundColor: '#FFF',
            marginBottom: 1
        }
    },
}));

function Index(props) {
    //console.log('props', props)
    let { User } = props;

    let history = useHistory();
    const classes = useStyles();
    const theme = useTheme();

    const isRealLogin = () => {
        return User.isLogin && (User.nickname != User.openid);
    }

    //console.log('useParams()', useParams());
    let { logintoken } = useParams();
    if (logintoken != undefined && logintoken.length > 0) {
        //save tokenmessage
        //console.log('logintoken',logintoken)
        //console.log('logintoken2',decodeURI(logintoken))
        let Token = JSON.parse(unescape(decodeURI(logintoken)))
        if (Token.loginToken != User.loginToken && !isRealLogin()) {
            global.store.dispatch(setWinXinLoginMsg(Token));
            if (Token.oldurl != undefined && Token.oldurl != null && Token.oldurl.length > 0)
                window.location.href = Token.oldurl;
            else
                window.location.href = '/meindex';
        }
    }

    const wxLogin = () => {
        let openId = localStorage.getItem('shareOpenId');
        console.log('openId', openId);

        if (process.env.NODE_ENV === 'development') {
            //开发模式，实现自动登录
            window.location.href = APIBaseUrl + '/Login/WXLogin_Development';
        }
        else {
            //授权微信openid登陆
            let SCOPE = 'snsapi_userinfo';
            let REDIRECT_URI = APIBaseUrl + '/Login/WXLogin?popenId=' + (openId == null ? '' : openId);
            let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WXAppID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=code&scope=${SCOPE}&state=STATE#wechat_redirect`
            window.location.href = url;
        }
    }

    const userLogout = async () => {
        if (window.confirm('确定要退出登录吗？')) {
            window.location.href = '/logout';
        }
    }
    const messageClick = () => {
        history.push('/me/message');
    }


    const [info, setInfo] = useState({});
    useEffect(() => {
        if (isRealLogin())
            Get(`/Member/Member/GetMemberInfo`).then((json) => {
                console.log('GetMemberInfo', json);
                if (json.Code == 1)
                    setInfo(json.Result);
            });
    }, []);//通过传入一个空数组,我们告诉React不要跟踪任何更改,只运行一次,有效模拟componentDidMount.

    console.log('info', info)
    return (
        <Fragment>
            <div className='header' style={{ height: 168, backgroundColor: theme.palette.primary.light, padding: '20px 10px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', color: '#FFF' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}  >
                    <div>
                        <Avatar style={{ width: 56, height: 56 }} src={isRealLogin() && User.headimgurl}>
                            <AccountCircleIcon style={{ width: 56, height: 56 }} />
                        </Avatar>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, flex: 1 }}
                        onClick={() => { !isRealLogin() && wxLogin() }}
                    >
                        <Typography variant="subtitle1">{User.nickname}</Typography>
                        {isRealLogin() && <Typography variant="caption">{User.province + ' ' + User.city}</Typography>}
                    </div>
                    <div style={{ alignSelf: 'flex-start' }}>
                        {/* <IconButton size="small" aria-label="message" style={{ backgroundColor: '#FFF', padding: '5px', color: theme.palette.grey[400] }} onClick={() => isRealLogin() && messageClick()} >
                                <MessageIcon />
                            </IconButton> */}
                    </div>
                </div>
                <div style={{ height: 10 }}></div>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h6">{info.Pro_sc || '--'}</Typography>
                        <Typography variant="caption">商品收藏</Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h6">{info.integralTodayTotal || '--'}</Typography>
                        <Typography variant="caption">今日积分</Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h6">{info.achievementTodayTotal || '--'}</Typography>
                        <Typography variant="caption">今日佣金</Typography>
                    </div>
                </div>
            </div>
            <List component="nav" className={['body', classes.list].join(' ')}>
                <ListItem button onClick={() => isRealLogin() && history.push('/me/cost')}>
                    <ListItemIcon>
                        <StorefrontIcon />
                    </ListItemIcon>
                    <ListItemText primary="我的消费" />
                    <ListItemSecondaryAction>
                        <ChevronRightIcon />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem button onClick={() => isRealLogin() && history.push('/me/integral')}>
                    <ListItemIcon>
                        <LocalPlayIcon />
                    </ListItemIcon>
                    <ListItemText primary="我的积分" />
                    <ListItemSecondaryAction>
                        <ChevronRightIcon />
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem style={{ marginTop: 8 }} button onClick={() => isRealLogin() && history.push('/me/submember')}>
                    <ListItemIcon>
                        <PeopleAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="我的粉丝" />
                    <ListItemSecondaryAction>
                        <ChevronRightIcon />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem button onClick={() => isRealLogin() && history.push('/me/myshare')}>
                    <ListItemIcon>
                        <PersonAddIcon />
                    </ListItemIcon>
                    <ListItemText primary="我要圈粉" />
                    <ListItemSecondaryAction>
                        <ChevronRightIcon />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem button onClick={() => isRealLogin() && history.push('/me/createredpack')}>
                    <ListItemIcon>
                        <PersonAddIcon />
                    </ListItemIcon>
                    <ListItemText primary={<span>我要圈粉<span style={{ color: 'red' }}>（红包圈粉）</span></span>} />
                    <ListItemSecondaryAction>
                        <ChevronRightIcon />
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem style={{ marginTop: 8 }} button onClick={() => isRealLogin() && history.push('/me/achievement')}>
                    <ListItemIcon>
                        <MonetizationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary="我的佣金" />
                    <ListItemSecondaryAction>
                        <ChevronRightIcon />
                    </ListItemSecondaryAction>
                </ListItem>
                {/* <ListItem button>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="设置" />
                            <ListItemSecondaryAction>
                                <ChevronRightIcon />
                            </ListItemSecondaryAction>
                        </ListItem> */}
                {/* <ListItem button>
                            <ListItemIcon>
                                <DraftsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Drafts" />
                        </ListItem> */}
                <ListItem style={{ marginTop: 38 }} button onClick={() => isRealLogin() && userLogout()} >
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="注销" />
                </ListItem>

            </List>

            <Bottom chooseIndex={1} />
        </Fragment>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        User: state.User
    }
}

export default connect(
    mapStateToProps
)(Index);