/*global WeixinJSBridge*/
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux'
import Bottom from './Bottom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SearchIcon from '@material-ui/icons/Search';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import WinXinSDK from '../WinXinSDK';
import { Get, Post } from '../Request';
import Avatar from '@material-ui/core/Avatar';
import { APIBaseUrl, WXAppID, WebUrl, Name } from '../Global';
import wx from 'weixin-js-sdk'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useParams
} from "react-router-dom";
import { PostAdd } from '@material-ui/icons';


function RedpackPage(props) {
    let { User } = props;
    const theme = useTheme();
    let history = useHistory();

    //进入后获取推广者openid，和当前的人进行绑定
    let { number, openid } = useParams();
    console.log('number', number);
    console.log('openid', openid);

    let oldurl = `/redpackpage/${number}/sign/${openid}`;//当前页面网址

    if (openid.length > 0 && number.length > 0) {
        localStorage.setItem('shareOpenId', openid);

        if (!User.isLogin) {
            //let openId = localStorage.getItem('shareOpenId');
            //console.log('openId', openId);
            //授权微信openid登陆
            let SCOPE = 'snsapi_base';
            let REDIRECT_URI = APIBaseUrl + '/Login/WXLogin?oldurl=' + oldurl + '&popenId=' + (openid == null ? '' : openid);
            let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WXAppID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=code&scope=${SCOPE}&state=STATE#wechat_redirect`
            window.location.href = url;
        }
    }

    const [ps, setPs] = useState(null);
    const GetRedPackMsg = () => {
        Get(`/Public/RedpackMsg?number=${number}`)
            .then(json => {
                if (json.Code == 1)
                    setPs(json.Result);
                else {
                    alert(json.Message);
                    window.location.href = '/'
                }
            }).catch(err => {

            })
    }

    useEffect(() => {
        GetRedPackMsg();
    }, []);

    const isRealLogin = () => {
        return User.isLogin && (User.nickname != User.openid);
    }

    const [qianging, setQianging] = useState(false);
    const Confirm = () => {
        if (!isRealLogin()) {
            let openId = localStorage.getItem('shareOpenId');
            console.log('openId', openId);
            //授权微信openid登陆
            let SCOPE = 'snsapi_userinfo';
            let REDIRECT_URI = APIBaseUrl + '/Login/WXLogin?oldurl=' + oldurl + '&popenId=' + (openId == null ? '' : openId);
            let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WXAppID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=code&scope=${SCOPE}&state=STATE#wechat_redirect`
            window.location.href = url;
        }
        else if (!qianging && ps.LoginMemberHongBaoPrice == null) {

            //抢红包
            console.log('start qiang hongbao');
            setQianging(true);
            Post(`/Public/QiangRedpack`, { data: { number } })
                .then(json => {
                    if (json.Code == 1) { }
                    else {
                        alert(json.Message);
                    }
                    window.location.href = oldurl;
                }).catch(err => {
                    window.location.href = oldurl;
                })
        }
    }

    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = (scrollType) => {
        setOpen(true);
        setScroll(scrollType);
        GetRedPackMsg();
    };
    const handleClose = () => {
        setOpen(false);
    };
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);


    //构造微信分享参数信息
    const createWXParams = (returnOpenId) => {
        //returnOpenId = openid; //手动调整推广者（该页面推广者固定是发红包的人，不可能变成登陆人）
        return {
            title: `您的红包已到账，请点击领取 ${ps.MemberName} 发的红包`,
            desc: ps.Redpck.remark,
            link: `${WebUrl}` + oldurl,
            imgUrl: `${WebUrl}/redpack.jpg`
        }
    };

    const WXShareSuccess = () => {
    };

    if (ps == null) {
        return (
            <span>加载中...</span>
        )
    }

    return (

        <Fragment>
            <WinXinSDK
                onMenuShareAppMessage={(returnOpenId) => {
                    return {
                        ...createWXParams(returnOpenId),
                        success: function () {
                            // 用户点击了分享后执行的回调函数
                            WXShareSuccess();
                        }
                    };
                }}
                onMenuShareTimeline={(returnOpenId) => {
                    return {
                        ...createWXParams(returnOpenId),
                        success: function () {
                            // 用户点击了分享后执行的回调函数
                            WXShareSuccess();
                        }
                    };
                }}
            />
            <AppBar elevation={0} className='header' style={{ backgroundColor: 'transparent' }}>
                <Toolbar>
                    <IconButton onClick={() => { window.location.href = '/'; }} edge="start" color="inherit" >
                        <AccountBalanceIcon style={{ color: 'rgb(236, 204, 155)' }} />
                    </IconButton>
                    <div style={{ flex: 1 }}></div>
                    <Button onClick={() => { handleClickOpen('paper') }} style={{ color: theme.palette.getContrastText(theme.palette.primary.main) }}>红包记录</Button>
                </Toolbar>
            </AppBar>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#cf5240', minHeight: '100vh', position: "relative", overflow: 'hidden' }}>
                <div style={{ width: 1000, height: 1000, top: 'calc(-1000px + 60vh)', backgroundColor: '#dd5847', position: 'absolute', borderRadius: '50%', zIndex: 1, "box-shadow": "0 0 20px 5px #ff6e5b" }}></div>
                <div style={{ height: 60 }}></div>
                <Avatar alt={ps.MemberName} src={ps.MemberHeadimgurl} style={{ width: 100, height: 100, zIndex: 10 }} />

                <Typography variant="subtitle1" style={{ zIndex: 10, color: '#eccc9b', paddingTop: 6 }}>{ps.MemberName + ' 发的红包'}</Typography>

                <Typography variant="h4" style={{ zIndex: 10, color: '#eccc9b', padding: '0 30px', marginTop: 40 }}>{ps.Redpck.remark}</Typography>

                <div onClick={() => Confirm()} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 100, height: 100, top: 'calc(60vh - 50px)', position: 'absolute', borderRadius: '50%', zIndex: 10, backgroundColor: '#eccc9b', "box-shadow": "rgb(255 209 137) 0px 0px 6px 1px" }}>
                    {
                        ps.LoginMemberHongBaoPrice == null ? (
                            qianging ? <CircularProgress style={{ color: '#a74335' }} />
                                : <span style={{ fontSize: 50 }}>開</span>
                        ) :
                            <div style={{}}><span style={{ fontSize: 15 }}>￥</span><span style={{ fontSize: 40 }}>{ps.LoginMemberHongBaoPrice}</span></div>
                    }
                </div>

                <div onClick={() => { window.location.href = '/'; }} style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: "center", position: 'fixed', bottom: 5, zIndex: 10 }}>
                    <img style={{ width: 20, height: 20, marginRight: 5 }} src={process.env.PUBLIC_URL + '/logo.jpg'} /><Typography variant="overline" style={{ color: '#FFF', }}>爱莱出行</Typography>
                </div>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">抢取红包记录</DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                            style={{}}
                        >
                            {
                                ps.HongBaos.map((item, index) => {
                                    return (
                                        <Fragment>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px 0 0' }}>
                                                <Avatar style={{ width: 30, height: 30 }} src={item.MemberHeadimgurl} />
                                                <div style={{
                                                    width: '80vw', margin: '0 5px', fontSize: 14, 
                                                    'text-overflow': 'ellipsis',
                                                    'white-space': 'nowrap',
                                                    overflow: 'hidden'
                                                }}>{item.MemberName}</div>
                                                <div style={{ fontSize: 14 }}>￥{item.Price}</div>
                                            </div>
                                        </Fragment>
                                    );
                                })

                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button onClick={handleClose} color="primary">
                            关闭(Close)
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        User: state.User
    }
}

export default connect(
    mapStateToProps
)(RedpackPage);