import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ReactPullLoad2 from '../ReactPullLoad2';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import PeopleIcon from '@material-ui/icons/People';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

import { Get, Post } from '../../Request';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    cont: {
        paddingTop: 131,
        flex: 1,
    }
}));


function Integral(props) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const back = () => {
        history.goBack();
    }

    const [statistics, setStatistics] = useState({});
    useEffect(() => {
        Get(`/Member/Member/GetIntegralStatistics`).then((json) => {
            console.log('GetIntegralStatistics', json);
            if (json.Code == 1)
                setStatistics(json.Result);
        });
    }, []);

    return (
        <Fragment>
            <AppBar elevation={0} className='header'>
                <Toolbar>
                    <IconButton onClick={() => back()} edge="start" color="inherit" >
                        <ChevronLeftIcon fontSize="large" />
                    </IconButton>
                    <Typography variant="subtitle1" >
                        我的积分
                </Typography>
                </Toolbar>
                <div style={{backgroundColor: theme.palette.primary.main, color: theme.palette.getContrastText(theme.palette.primary.main) }}>
                    <div style={{ padding: '20px 0', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <Typography variant="h6">总共 {statistics.integralCount || '--'} 积分</Typography>
                    </div>
                </div>
            </AppBar>
            <ReactPullLoad2
                className={['body', classes.cont].join(' ')}
                loadData={(page) => {
                    return Get(`/Member/Member/GetIntegralList?page=${page}`);
                }}

            >
                {(data, setData) => (
                    <List>
                        {data.map((item, index) => {
                            let jifen = item.payerTotal / 10;
                            if (jifen < 1) jifen = 1;
                            jifen = parseInt(jifen);
                            return (
                                <ListItem onClick={() => {
                                    //itemClick(item)
                                }}>
                                    <ListItemText className={classes.ListItemText} primary={"[" + item.CreateTime + "]" + item.Title} secondary={item.Content} />
                                    <ListItemSecondaryAction>
                                        <Typography variant="title">{(item.IntegralCount > 0 ? '+' : '-') + item.IntegralCount}</Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                )}
            </ReactPullLoad2>
        </Fragment>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        User: state.User
    }
}

export default connect(
    mapStateToProps
)(Integral);