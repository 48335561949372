import { logout } from '../../Redux/Action'

const Logout = (props) => {

    global.store.dispatch(logout()).then(() => {
        console.log('退出成功');
        window.location.href = '/meindex';
    });

    return (
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div>退出中...</div>
        </div>
    );

}

export default Logout;