import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ReactPullLoad, { STATS } from "react-pullload";

function ReactPullLoad2(props) {

    const [hasMore, setHasMore] = useState(true);
    const [data, setData] = useState([]);
    const [action, setAction] = useState(STATS.init);
    const [index, setIndex] = useState(0);

    /* useEffect(() => {
        if (action === STATS.init) {
            //刷新
            handRefreshing();
        }
    }, []); */
    useEffect(() => {
        //刷新
        handRefreshing();
    }, [props.params]);

    const handleAction = _action => {
        console.info(_action, action, _action === action);
        //new action must do not equel to old action
        if (_action === action) {
            return false;
        }
        if (_action === STATS.refreshing) {
            //刷新
            handRefreshing();
        } else if (_action === STATS.loading) {
            //加载更多
            handLoadMore();
        } else {
            //DO NOT modify below code
            setAction(_action);
        }
    };
    const handRefreshing = () => {
        if (STATS.refreshing === action) {
            return false;
        }
        setTimeout(async () => {
            let cData = [];
            let result = props.loadData && await props.loadData(0);
            if (result.Code == 1) cData = result.Result;
            else alert(result.Message);
            //refreshing complete
            setData(cData);
            setHasMore(true);
            setAction(STATS.refreshed);
            setIndex(1);
        }, 10);

        setAction(STATS.refreshing);
    };
    const handLoadMore = () => {
        if (STATS.loading === action) {
            return false;
        }
        //无更多内容则不执行后面逻辑
        if (!hasMore) {
            return;
        }

        setTimeout(async () => {
            //loadMore complete
            let cData = [];
            let result = props.loadData && await props.loadData(index);
            if (result.Code == 1) cData = result.Result;
            else alert(result.Message);
            if (cData.length == 0) {
                setAction(STATS.reset);
                setHasMore(false);
            } else {
                //let addData = ['123', '456'];
                setData([...data, ...cData]);
                setAction(STATS.reset);
                setIndex(index + 1);
            }
        }, 10);

        setAction(STATS.loading);
    };

    return (
        <ReactPullLoad
            downEnough={150}
            action={action}
            handleAction={handleAction}
            hasMore={hasMore}
            distanceBottom={1000}
            //className={props.className}
            {...props}
        >
            {props.children(data, setData)}
        </ReactPullLoad>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        //User: state.User
    }
}

export default connect(
    mapStateToProps
)(ReactPullLoad2);