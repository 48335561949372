import React, { Fragment, useEffect, useState } from 'react';
import wx from 'weixin-js-sdk'
import { Get, Post } from './Request';
import { WXAppID, WebUrl, Name } from './Global';

const WinXinSDK = (props) => {

    useEffect(() => {
        const { User } = global.store.getState();
        let token = User.loginToken;
        let openId = localStorage.getItem('shareOpenId');
        openId = openId == null ? '' : openId;
        let data = {
            url: process.env.NODE_ENV === 'development' ? WebUrl : window.location.href,
            openId, token
        };
        Post('/Public/GetWXConfig', { data }).then(async (json) => {
            //alert(JSON.stringify(json));
            //console.log('GetWXConfig', json);
            if (json.Code == 1) {
                let { nonceStr, timestamp, signature, returnOpenId } = json.Result;

                //alert('配置 wx.config');
                wx.config({
                    debug: process.env.NODE_ENV === 'development', //开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: WXAppID, // 必填，公众号的唯一标识
                    timestamp, // 必填，生成签名的时间戳
                    nonceStr, // 必填，生成签名的随机串
                    signature,// 必填，签名
                    //jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage'] // 必填，需要使用的JS接口列表 新接口有问题
                    jsApiList: ['chooseWXPay', 'onMenuShareAppMessage', 'onMenuShareTimeline', 'getLocation', 'openLocation'] // 必填，需要使用的JS接口列表
                });

                wx.ready(() => {
                    //alert('设置 updateAppMessageShareData & updateTimelineShareData');
                    // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。

                    let title = `${Name}：这个真给力，今天你来了吗～共享同城消费资源`;
                    let desc = `这个真给力，今天你来了吗～共享同城消费资源`;
                    let link = `${WebUrl}/sign/${returnOpenId}`;
                    let imgUrl = `${WebUrl}/logo.jpg`;

                    props.onMenuShareAppMessage ?
                        wx.onMenuShareAppMessage(props.onMenuShareAppMessage(returnOpenId))
                        :
                        wx.onMenuShareAppMessage({
                            title, // 分享标题
                            desc, // 分享描述
                            link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl, // 分享图标
                            //type: '', // 分享类型,music、video或link，不填默认为link
                            //dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                            success: function () {
                                // 用户点击了分享后执行的回调函数
                                //console.log('onMenuShareAppMessage  Success', params);
                                //alert(link)
                            }
                        })
                    props.onMenuShareTimeline ?
                        wx.onMenuShareTimeline(props.onMenuShareTimeline(returnOpenId))
                        :
                        wx.onMenuShareTimeline({
                            title, // 分享标题
                            link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl, // 分享图标
                            success: function () {
                                // 用户点击了分享后执行的回调函数
                                //console.log('onMenuShareTimeline  Success', params);
                                //alert(link)
                            }
                        })

                    //props.updateAppMessageShareData && wx.updateAppMessageShareData(props.updateAppMessageShareData(returnOpenId))
                    //props.updateTimelineShareData && wx.updateTimelineShareData(props.updateTimelineShareData(returnOpenId))
                });
            }
        })
    }, []);

    return null;
}

export default WinXinSDK;