import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import './index.css';
import "../node_modules/react-pullload/dist/ReactPullLoad.css";
import App from './App';
//import reportWebVitals from './reportWebVitals';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { Provider } from "react-redux";
import configureStore from "./Redux/Store"; //引入之前创建的store
global.store = configureStore();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3f51b5"
    },
    secondary: {
      main: "#ff8d00"
    },
    success: {
      main: '#018001',
    }
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={global.store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
