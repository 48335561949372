import { APIBaseUrl } from './Global';
import { compareAsc, format } from 'date-fns'
import { logout } from './Redux/Action'
import axios from 'axios';
import qs from 'qs';

export const Get = (url) => {
    url = url.toLowerCase().indexOf(APIBaseUrl.toLowerCase()) == 0 ? url : (APIBaseUrl + url);
    return new Promise((resolve, reject) => {
        const { User } = global.store.getState();
        let Authorization = User.loginToken;
        let shareOpenId = localStorage.getItem('shareOpenId')
        shareOpenId = shareOpenId == null ? '' : shareOpenId;

        axios({
            method: 'get',
            url: url, //`${APIBaseUrl}/Member/Member/GetMemberMessageList?page=${page}`,
            headers: { Authorization, shareOpenId }
        }).then(function (response) {
            resolve(response.data);
        }).catch(async function (error) {
            if (error.response) {
                const { status, statusText, data } = error.response;
                switch (status) {
                    case 401:
                        //logout
                        window.location.href = '/logout';
                        break;
                    default:
                        resolve({ Code: 0, Message: data || statusText, Result: null });
                        break;
                }
            }
            else
                resolve({ Code: 0, Message: error, Result: null });
        })
    });
}
export const Post = (url, { data, contentType = 'application/x-www-form-urlencoded' }) => {
    url = url.toLowerCase().indexOf(APIBaseUrl.toLowerCase()) == 0 ? url : (APIBaseUrl + url);
    return new Promise((resolve, reject) => {
        const { User } = global.store.getState();
        let Authorization = User.loginToken;
        let shareOpenId = localStorage.getItem('shareOpenId')
        shareOpenId = shareOpenId == null ? '' : shareOpenId;

        axios({
            method: 'post',
            url: url,
            headers: { Authorization, shareOpenId, 'Content-Type': contentType },
            data: contentType === 'application/x-www-form-urlencoded' ? qs.stringify(data) : data
        }).then(function (response) {
            resolve(response.data);
        }).catch(async function (error) {
            if (error.response) {
                const { status, statusText, data } = error.response;
                switch (status) {
                    case 401:
                        //logout
                        window.location.href = '/logout';
                        break;
                    default:
                        resolve({ Code: 0, Message: data || statusText, Result: null });
                        break;
                }
            }
            else
                resolve({ Code: 0, Message: error, Result: null });
        })
    });
}