import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory, useRouteMatch } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';


import Avatar from '@material-ui/core/Avatar';

import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { Get, Post } from '../../Request';

import ReactPullLoad2 from '../ReactPullLoad2';
import { APIBaseUrl } from '../../Global';
import { compareAsc, format } from 'date-fns'
import axios from 'axios';

import Dialog from '@material-ui/core/Dialog';


import MessageDetail from './MessageDetail';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1, display: 'flex', flexDirection: 'column'
    },
    cont: {
        flex: 1, paddingTop: 56
    },
    ListItemText: {
        '& .MuiListItemText-primary': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        '& .MuiListItemText-secondary': {
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': 3,
            overflow: 'hidden'
        }
    }
}));

function Message(props) {
    const { path, url, params } = useRouteMatch()
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const back = () => {
        history.goBack();
    }


    const itemClick = (item) => {
        setData(item);
        handleClickOpen();
    }

    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState(null);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <div className={classes.root}>
                <AppBar position="fixed">
                    <Toolbar>
                        <IconButton onClick={() => back()} edge="start" color="inherit" >
                            <ChevronLeftIcon fontSize="large" />
                        </IconButton>
                        <Typography variant="subtitle1" >
                            我的消息
                    </Typography>
                    </Toolbar>
                </AppBar>

                <ReactPullLoad2
                    className={classes.cont}
                    loadData={(page) => {
                        /*
                        return new Promise((resolve, reject) => {
                            //Authorization
                            axios({
                                method: 'get',
                                url: `${APIBaseUrl}/Member/Member/GetMemberMessageList?page=${page}`,
                                headers: { 'Authorization': '0422CFC881B3F05B169110DF34741A17' }
                            }).then(function (response) {
                                resolve(response.data);
                            }).catch(function (error) {
                                resolve({ Code: 0, Message: error, Result: null });
                            })
                        });
                        */
                        return Get(`/Member/Member/GetMemberMessageList?page=${page}`);
                    }}

                >
                    {(data, setData) => (
                        <List>
                            {data.map((item, index) => {
                                return (
                                    <ListItem onClick={() => {
                                        itemClick(item)
                                        setTimeout(() => {
                                            if (!item.Read) {
                                                item.Read = true;
                                                data[index] = item;
                                                setData(data);
                                            }
                                        }, 1000);
                                    }}>
                                        <ListItemAvatar >
                                            <Avatar style={{ backgroundColor: item.Read ? theme.palette.grey[400] : theme.palette.primary.main }}>
                                                <MailOutlineIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText className={classes.ListItemText} primary={item.Title} secondary={<React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                style={{
                                                    color: theme.palette.grey[400],
                                                }}
                                            >
                                                {format(new Date(item.CreateTime), 'MM-dd HH:mm')}
                                            </Typography>
                                            &nbsp;
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                style={{
                                                    color: theme.palette.grey[500],
                                                }}
                                            >
                                                {item.Content}
                                            </Typography>
                                        </React.Fragment>} />
                                        <ListItemSecondaryAction>
                                            <ChevronRightIcon />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                        </List>
                    )}
                </ReactPullLoad2>
            </div>
            {/* <Switch>
                <Route path={`${path}/detail/:id`}>
                    <MessageDetail />
                </Route>
            </Switch> */}
            <Dialog fullScreen open={open} onClose={handleClose}>
                <MessageDetail handleClose={handleClose} data={data} />
            </Dialog>
        </React.Fragment>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        User: state.User
    }
}

export default connect(
    mapStateToProps
)(Message);