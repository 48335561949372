/*global WeixinJSBridge*/
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux'
import Bottom from './Bottom';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SearchIcon from '@material-ui/icons/Search';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import WinXinSDK from '../WinXinSDK';
import { Get, Post } from '../Request';
import { APIBaseUrl, WXAppID, WebUrl, Name } from '../Global';
import wx from 'weixin-js-sdk'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useParams
} from "react-router-dom";


function Pay(props) {
    let { User } = props;
    const theme = useTheme();
    let history = useHistory();
    let { storeId, wx_Sub_Mchid } = useParams();

    const isRealLogin = () => {
        return User.isLogin && (User.nickname != User.openid);
    }

    if (!isRealLogin()) {
        let oldurl = `/Pay/${storeId}-${wx_Sub_Mchid}.html`;
        let openId = localStorage.getItem('shareOpenId');
        console.log('openId', openId);
        //授权微信openid登陆
        let SCOPE = 'snsapi_userinfo';
        let REDIRECT_URI = APIBaseUrl + '/Login/WXLogin?oldurl=' + oldurl + '&popenId=' + (openId == null ? '' : openId);
        let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WXAppID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=code&scope=${SCOPE}&state=STATE#wechat_redirect`
        window.location.href = url;
    }

    let [value, setValue] = useState('');
    const onChange = (event) => {
        let val = event.target.value.trim();
        //let val2 = parseFloat(val);
        //if (isNaN(val2)) val2 = ''
        //else val2 = val2.toFixed(2)
        setValue(val);
    }



    const Confirm = () => {
        console.log('value', value.trim());
        let price = parseFloat(value.trim());
        if (isNaN(price)) {
            alert('请输入正确的金额')
        }
        else {
            price = price.toFixed(2);
            setValue(price.toString());//修正金额

            //发起支付
            Post('/WXPublic/SendPay', { data: { price, storeId, wx_Sub_Mchid } })
                .then(json => {
                    if (json.Code == 1) {
                        let { timeStamp, nonceStr, signType, paySign } = json.Result;
                        let can = {
                            "appId": WXAppID,        //公众号ID，由商户传入     
                            "timeStamp": timeStamp,        //时间戳，自1970年以来的秒数     
                            "nonceStr": nonceStr,       //随机串     
                            "package": json.Result.package,
                            "signType": signType,       //微信签名方式：     
                            "paySign": paySign //微信签名 
                        };
                        WeixinJSBridge.invoke('getBrandWCPayRequest', can,
                            function (res) {
                                if (res.err_msg == "get_brand_wcpay_request:ok") {
                                    // 使用以上方式判断前端返回,微信团队郑重提示：
                                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                    console.log('支付成功了', 'res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠');
                                    window.location.href = '/';
                                } else {
                                    alert('取消了支付')
                                }
                            });
                        /*  wx.chooseWXPay({
                             appId: WXAppID,
                             timestamp: timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                             nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
                             package: json.Result.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                             signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                             paySign: paySign, // 支付签名
                             success: function (res) {
                                 // 支付成功后的回调函数
                                 window.location.href = '/';
                             },
                             cancel: function () {
                                 alert('取消了支付')
                             }
                         }); */
                    }
                    else {
                        alert(json.Message)
                        window.location.href = '/meindex';
                    }
                }
                ).catch(err => {
                    alert(err);
                });
        }
    }

    if (storeId == undefined || wx_Sub_Mchid == undefined)
        return (<div>Err</div>)

    return (
        <Fragment>
            <WinXinSDK />
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#FFF' }}>
                <img style={{ width: 60, padding: '10px 0' }} src={process.env.PUBLIC_URL + '/logo.jpg'} />
                <Typography variant="h5">爱莱出行</Typography>
                <Typography variant="body2" style={{ color: theme.palette.grey[500], marginTop: 6 }}>收款页面</Typography>
                <div style={{ height: 30 }}></div>
                <input type='text' value={value} onChange={(e) => onChange(e)} placeholder='请输入付款金额' style={{
                    height: 50, width: '80%',
                    borderColor: theme.palette.grey[500], borderWidth: 1, borderStyle: 'solid',
                    borderRadius: 4, padding: '0 10px',
                    fontSize: 26
                }} />
                <Button onClick={() => Confirm()} variant="contained" color="primary" style={{ marginTop: 20, height: 45, width: '80%', borderRadius: 4 }}>
                    确认支付
                </Button>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        User: state.User
    }
}

export default connect(
    mapStateToProps
)(Pay);