import React, { useState } from 'react';
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';


import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';

import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import { useTheme } from '@material-ui/core/styles';
import { Post } from '../Request';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useParams
} from "react-router-dom";

function DetailBottom(props) {
    const theme = useTheme();
    let history = useHistory();
    let { User } = props;
    let { pro, store } = props.ps;
    const [shoucang, setShoucang] = useState(pro.ShouCang);

    const Btn = (props) => {
        return (<div {...props} style={{ display: 'flex', flexDirection: 'column', color: '#999', padding:'0 15px', alignItems: 'center' }}>
            {props.icon}
            <Typography style={{ fontSize: 9, }}>{props.txt}</Typography>
        </div>)
    }

    const shouCang = () => {
        if (!User.isLogin)
            window.location.href = '/meindex';
        else
            Post('/Public/ShouCangProduct', { data: { id: pro.id } })
                .then(json => {
                    if (json.Code == 1)
                        setShoucang(json.Result);
                    else {
                        alert(json.Message);
                    }
                }).catch(err => {

                });
    }

    const tel = () => {
        //alert('tel:' + store.ContactsMobile);
        window.location.href = 'tel:' + store.ContactsMobile;
    }

    return (
        <div style={{ position: 'fixed', bottom: 0, width: '100%', maxWidth: 750, display: 'flex', padding: '5px 10px', background: "rgb(243 243 243)" }}>
            <Btn onClick={() => { window.location.href = '/' }} txt='主页' icon={<HomeIcon style={{ color: '#333' }} />} />
            {/* <Btn txt='电话' icon={<PhoneIcon style={{ color: '#333' }} />} /> */}
            <Btn onClick={() => shouCang()} txt='收藏' icon={shoucang ? <FavoriteIcon style={{ color: 'red' }} /> : <FavoriteBorderIcon style={{ color: '#333' }} />} />
            <Button onClick={() => tel()} variant="contained" style={{ backgroundColor: theme.palette.secondary.main, color: '#FFF', flex: 1, marginLeft: 10 }}>
                拨打电话{/* {props.User.nickname} */}
            </Button>
        </div>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        User: state.User
    }
}

export default connect(
    mapStateToProps
)(DetailBottom);