import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import history from './History'

import Logout from './components/me/Logout';
import Index from './components/Index';
import Detail from './components/Detail';
import Search from './components/Search';
import RedpackPage from './components/RedpackPage';
import SearchList from './components/SearchList';
import MeIndex from './components/me/Index';
import Message from './components/me/Message';
import MessageDetail from './components/me/MessageDetail';
import MyShare from './components/me/MyShare';
import CreateRedpack from './components/me/CreateRedpack';
import Redpack from './components/me/Redpack';
import Pay from './components/Pay';
import SubMember from './components/me/SubMember';
import Achievement from './components/me/Achievement';
import Cost from './components/me/Cost';
import Integral from './components/me/Integral';

import { initWinXinLoginMsg } from './Redux/Action'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";

function App() {

  //初始加入以后的信息，如果有
  global.store.dispatch(initWinXinLoginMsg());

  //const [value, setValue] = React.useState(0);

  /* function toHomeIndex() {
    console.log('history', history)
    history.push('/meindex');
  } */

  return (
    <Router history={history}>
        <Switch>
          <Route path="/logout">
            <Logout />
          </Route>
          <Route path="/meindex/:logintoken?">
            <MeIndex />
          </Route>
          <Route path="/me/message">
            <Message />
          </Route>
          <Route path="/me/myshare">
            <MyShare />
          </Route>
          <Route path="/me/createredpack">
            <CreateRedpack />
          </Route>
          <Route path="/me/redpack">
            <Redpack />
          </Route>
          <Route path="/me/submember">
            <SubMember />
          </Route>
          <Route path="/me/achievement">
            <Achievement />
          </Route>
          <Route path="/me/cost">
            <Cost />
          </Route>
          <Route path="/me/integral">
            <Integral />
          </Route>

          <Route path="/pay/:storeId-:wx_Sub_Mchid.html">
            <Pay />
          </Route>

          <Route path="/detail/:id?/sign/:openid?">
            <Detail />
          </Route>
          <Route path="/detail/:id?">
            <Detail />
          </Route>

          <Route path="/search/list/:key?">
            <SearchList />
          </Route>
          <Route path="/search">
            <Search />
          </Route>
          
          {/* 红包 */}
          <Route path="/redpackpage/:number/sign/:openid">
            <RedpackPage />
          </Route>

          <Route path="/sign/:openid?">
            <Index />
          </Route>
          <Route path="/">
            <Index />
          </Route>

        </Switch>
    </Router>
  );
}

export default App;
