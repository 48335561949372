import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DetailBottom from './DetailBottom';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SearchIcon from '@material-ui/icons/Search';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ShareIcon from '@material-ui/icons/Share';

import WinXinSDK from '../WinXinSDK';
import wx from 'weixin-js-sdk'
import { WXAppID, WebUrl, Name, APIBaseUrl } from '../Global';
import { Get, Post } from '../Request';

import { useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';

import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import ReactPlayer from 'react-player'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useParams
} from "react-router-dom";

SwiperCore.use([Autoplay]);

const useStyles = makeStyles({
    contDiv: {
        '& p': { margin: 0 },
        '& img': { maxWidth: '100%', verticalAlign: 'middle' }
    },
});

function Detail(props) {
    let { User } = props;
    const classes = useStyles();
    const theme = useTheme();
    let history = useHistory();
    let { id, openid } = useParams();
    console.log('openid', openid, 'id', id);
    if (openid != undefined && openid.length > 0) {
        localStorage.setItem('shareOpenId', openid);

        if (!User.isLogin) {
            let oldurl = `/detail/${id}`;
            //let openId = localStorage.getItem('shareOpenId');
            //console.log('openId', openId);
            //授权微信openid登陆
            let SCOPE = 'snsapi_base';
            let REDIRECT_URI = APIBaseUrl + '/Login/WXLogin?oldurl=' + oldurl + '&popenId=' + (openid == null ? '' : openid);
            let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WXAppID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=code&scope=${SCOPE}&state=STATE#wechat_redirect`
            window.location.href = url;
        }
    }
    if (id == undefined)
        window.location.href = '/'

    const [ps, setPs] = useState(null);

    const [showShare, setShowShare] = useState(false);
    const [showPic, setShowPic] = useState(null);

    useEffect(() => {
        Get(`/Public/ProductDetail?id=${id}`)
            .then(json => {
                if (json.Code == 1)
                    setPs(json.Result);
                else {
                    alert(json.Message);
                    window.location.href = '/'
                }
            }).catch(err => {

            })
    }, []);

    const createReactSwiperImages = () => {
        return ps.pro.Images.split(',').map((item, index) => {
            return { image: item };
        });
    }

    /* const swiperOptions = {
        preloadImages: true,
        autoplay: 4000,
        autoplayDisableOnInteraction: false
    }; */
    const back = () => {
        if (window.history.length > 1)
            history.goBack();
        else
            window.location.href = '/';
    }
    const share = ()=>{
        //alert(123)
        const { User } = global.store.getState();
        let token = User.loginToken;
        let openId = localStorage.getItem('shareOpenId');
        openId = openId == null ? '' : openId;
        let data = {
            url: process.env.NODE_ENV === 'development' ? WebUrl : window.location.href,
            openId, token
        };
        Post('/Public/GetWXConfig', { data }).then(async (json) => {
            //alert(JSON.stringify(json));
            console.log('GetWXConfig', json);
            if (json.Code == 1) {
                let { nonceStr, timestamp, signature, returnOpenId } = json.Result;
                let link = encodeURIComponent(`${WebUrl}/detail/${id}/sign/${returnOpenId}`);
                Get(`/Home/t1?id=${id}&url=${link}`).then(async (data) => {
                    setShowShare(true);
                    setShowPic(data);
                });
            }
        });
    }
    const toLocation = () => {
        /* alert(ps.store.Coordinate.split(',')[0])
        alert(ps.store.Coordinate.split(',')[1]) */
        wx.openLocation({
            latitude: parseFloat(ps.store.Coordinate.split(',')[1]), // 纬度，浮点数，范围为90 ~ -90
            longitude: parseFloat(ps.store.Coordinate.split(',')[0]), // 经度，浮点数，范围为180 ~ -180。
            name: ps.store.FullName, // 位置名
            address: `${ps.store.Province}${ps.store.City}${ps.store.Area}${ps.store.Address}`, // 地址详情说明
            scale: 15, // 地图缩放级别,整型值,范围从1~28。默认为最大
            infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
        });
    }

    //构造微信分享参数信息
    const createWXParams = (returnOpenId) => {
        return {
            title: `${Name.replace('出行','')}：${ps.pro.Title}`,
            desc: ps.pro.SubTitle,
            link: `${WebUrl}/detail/${id}/sign/${returnOpenId}`,
            imgUrl: ps.pro.Images.split(',')[0].replace('https://', 'http://') + '?x-oss-process=image/resize,m_fill,w_100,h_100',
        }
    };

    const WXShareSuccess = () => {
        Post(`/Public/ProductWXShareSuccess`, { data: { id } })
            .then(json => {
            }).catch(err => {
            })
    };

    let [playing, setPlaying] = useState(false);

    if (ps == null)
        return (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: '5px' }}>
                <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
                <Skeleton variant="rect" height={150} />
                <Skeleton variant="text" />
                <Skeleton variant="text" width='65%' />
                <div style={{ display: 'flex', }}>
                    <Skeleton variant="text" width={50} />
                    <div style={{ flex: 1 }}></div>
                    <Skeleton variant="text" width={30} />
                    <div style={{ width: 10 }}></div>
                    <Skeleton variant="text" width={30} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                    <Skeleton variant="circle" width={40} height={40} />
                    <div style={{ width: 10 }}></div>
                    <Skeleton style={{ flex: 1 }} variant="text" height={20} />
                </div>
                <Skeleton variant="rect" style={{ flex: 1, marginTop: 20 }} />
            </div>
        );
    else {
        let { pro, store } = ps;
        return (
            <Fragment>
                <WinXinSDK
                    onMenuShareAppMessage={(returnOpenId) => {
                        return {
                            ...createWXParams(returnOpenId),
                            success: function () {
                                // 用户点击了分享后执行的回调函数
                                WXShareSuccess();
                            }
                        };
                    }}
                    onMenuShareTimeline={(returnOpenId) => {
                        return {
                            ...createWXParams(returnOpenId),
                            success: function () {
                                // 用户点击了分享后执行的回调函数
                                WXShareSuccess();
                            }
                        };
                    }}
                />
                <div className={['body'].join(' ')} style={{ paddingTop: 0, paddingBottom: 52 }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', zIndex: 12, top: 10, left: 10, color: '#FFF', borderRadius: 3, padding: 2 }}>
                                <ChevronLeftIcon onClick={() => back()} fontSize="large" />
                                 
                            </div>
                            <div style={{ position: 'absolute', zIndex: 12, top: 10, right: 10, color: '#FFF', borderRadius: 3, padding: 2 }}>
                                <ShareIcon onClick={() => share()} fontSize="large" />
                            </div>
                            {playing &&
                                <ReactPlayer
                                    onClick={() => { setPlaying(false); }}
                                    width='100%' height='200px'
                                    style={{ position: 'absolute', top: 0, left: 0, zIndex: 11, backgroundColor: '#FFF' }}
                                    url={ps.pro.VideoPath} loop={true}
                                    //微信内置浏览器 非全屏播放视频解析
                                    webkit-playsinline="true" /*这个属性是ios 10中设置可以让视频在小窗内播放，也就是不是全屏播放*/
                                    playsinline="true"  /*IOS微信浏览器支持小窗内播放*/
                                    x-webkit-airplay="allow"
                                    x5-video-player-type="h5"  /*启用H5播放器,是wechat安卓版特性*/
                                    x5-video-player-fullscreen="true" /*全屏设置，设置为 true 是防止横屏*/
                                    x5-video-orientation="portraint" /*播放器支付的方向，landscape横屏，portraint竖屏，默认值为竖屏*/
                                //微信内置浏览器 非全屏播放视频解析END
                                />
                            }
                            {/* <ReactSwiper swiperOptions={swiperOptions} items={createReactSwiperImages()} /> */}
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={1}
                                loop={true}
                                autoplay={playing ? false : { disableOnInteraction: false }}
                                onSlideChange={() => console.log('slide change')}
                                //onSwiper={(swiper) => console.log(swiper)}
                            >
                                {
                                    ps.pro.Images.split(',').map((item, index) => {
                                        return (
                                            <SwiperSlide>
                                                {({ isActive }) => {
                                                    if (index == 0 && ps.pro.VideoPath.length > 0)
                                                        return (<div style={{ width: '100%', height: 200, position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <img style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 200 }} src={item + '?x-oss-process=image/resize,m_fill,w_414,h_200,limit_0'} />
                                                            {/* <video style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 200 }}>
                                                                <source src={ps.pro.VideoPath} type="video/mp4" />
                                                            </video> */}
                                                            {!playing &&
                                                                <PlayCircleOutlineIcon onClick={() => {
                                                                    setPlaying(true);
                                                                    setTimeout(() => {
                                                                        var videos = document.getElementsByTagName("video");
                                                                        videos[0].play();
                                                                    }, 50)
                                                                }} style={{ width: 80, height: 80, zIndex: 10, color: theme.palette.grey[300] }} />
                                                            }
                                                        </div>)
                                                    else
                                                        return (<img style={{ width: '100%', height: 200 }} src={item + '?x-oss-process=image/resize,m_fill,w_414,h_200,limit_0'} />)
                                                }}
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>

                            <div style={{ position: 'absolute', zIndex: 10, bottom: 10, left: 10, backgroundColor: theme.palette.success.main, color: '#FFF', borderRadius: 3, padding: 2, }}>
                                <Typography variant="body2">{pro.State}</Typography>
                            </div>
                        </div>
                        <div style={{ padding: '0 8px', marginTop: 8 }}>
                            <Typography variant="subtitle1" >{pro.Title}</Typography>
                        </div>
                        <div style={{ padding: '0 8px' }}>
                            <Typography variant='caption' style={{ color: '#999', wordBreak: 'break-word' }}>{pro.SubTitle}</Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', color: '#999', padding: '0 8px', marginTop: 10, height: 32 }}>
                            <div><Typography variant='h6' style={{ color: theme.palette.secondary.main }}>￥{pro.Price}</Typography></div>
                            <div style={{ marginLeft: 10 }}><Typography variant='caption'>门市价：￥{pro.MarketPrice}</Typography></div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', color: '#999', padding: '0 8px', }}>
                            <div><Typography style={{ flex: 1 }} variant='caption'>已售 {pro.SalesCount}</Typography></div>
                            {/* <Typography style={{ flex: 1 }} variant='caption'>库存 83</Typography> */}
                        </div>

                        <div style={{ padding: 10 }} >
                            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#FFF', padding: 10, borderRadius: 5 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant='caption' component="div" style={{ color: '#999', marginRight: 10, }}>规则</Typography>
                                    <div style={{ flex: 1, }}><Typography variant='subtitle2'>{pro.GuiZe}</Typography></div>
                                </div>
                                <div style={{ height: 8 }}></div>
                                <div style={{ display: 'flex' }} onClick={() => toLocation()}>
                                    <Typography variant='caption' component="div" style={{ color: '#999', marginRight: 10, }}>门店</Typography>
                                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant='subtitle2'>{store.FullName}</Typography>
                                        <Typography variant='caption' style={{ color: '#999', }} >{store.Province + store.City + store.Area + store.Address}</Typography>
                                    </div>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        style={{ height: 35, backgroundColor: 'green', color: '#FFF' }}
                                        startIcon={<LocationOnIcon />}
                                    >导航走起</Button>
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: 10 }} >
                            <div className={classes.contDiv} style={{ backgroundColor: '#FFF', padding: 10, borderRadius: 5, fontSize: 12 }}
                                dangerouslySetInnerHTML={{ __html: pro.Content }}>
                            </div>
                        </div>
                        <div style={{ padding: 10 }} >
                            <div style={{ backgroundColor: '#FFF', padding: 10, border: '1px solid ' + theme.palette.secondary.main, borderRadius: 5, display: 'flex', flexDirection: 'column' }}>
                                <div style={{ alignSelf: 'center', marginBottom: 15, }}>
                                    温馨提示
                            </div>
                                <div className={classes.contDiv} style={{ fontSize: 12 }} dangerouslySetInnerHTML={{ __html: pro.Reminder }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DetailBottom ps={ps} />
                {
                    showShare && (
                <div onClick={()=>{ setShowShare(false);setShowPic(null); }} style={{backgroundColor:'#000000bd',width:'100%',height:'100vh',position: 'fixed',top:0,left:0,zIndex:99,display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <img src={showPic} style={{maxWidth:'80%',maxHeight:'80%'}} />
                </div>
                )
                }
            </Fragment>
        );
    }
}


const mapStateToProps = (state /*, ownProps*/) => {
    return {
        User: state.User
    }
}

export default connect(
    mapStateToProps
)(Detail);