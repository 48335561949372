import React, { Fragment, useEffect, useState, useRef } from 'react';
import Bottom from './Bottom';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SearchIcon from '@material-ui/icons/Search';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useTheme } from '@material-ui/core/styles';
import WinXinSDK from '../WinXinSDK';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
} from "react-router-dom";


export default function Search(props) {
    const theme = useTheme();
    let history = useHistory();
    const textInput = useRef(null);

    const back = () => {
        window.location.href = '/';
    }

    //let lishi = ['测试1', '测试2测试2测试2测试2', '测试3', '测试4', '测试5'];
    let [lishi, setLishi] = useState([]);

    useEffect(() => {
        let searchLishi = localStorage.getItem('searchLishi')
        searchLishi = searchLishi == null ? [] : JSON.parse(searchLishi);
        setLishi(searchLishi);

        textInput.current.focus();
    }, []);

    const clearHistory = () => {
        if (window.confirm('确定要清空搜索历史记录吗？')) {
            setLishi([])
            localStorage.setItem('searchLishi', JSON.stringify([]));
        }
    }

    const lishiItemClick = (text) => {
        //alert(text);
        history.push('/search/list/' + text);
    }

    let [value, setValue] = useState("");
    const handleChange = (event) => {
        setValue(event.target.value);
    }
    const handleSubmit = (event) => {
        console.log('handleSubmit', value);
        if (value.trim().length > 0)
            history.push('/search/list/' + value);
        else
            alert('请输入关键词');
        event.preventDefault();
    }

    return (
        <Fragment>
            <WinXinSDK />
            <div className='header' style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: 10, color: '#FFF' }}>
                    <form onSubmit={(e) => handleSubmit(e)} style={{ flex: 1, display: 'flex', alignItems: 'center', backgroundColor: 'rgb(255 255 255)', borderRadius: 50, padding: '5px', marginRight: 22 }}>
                        <SearchIcon style={{ color: '#666' }} /><input ref={textInput} value={value} onChange={(e) => handleChange(e)} className='searchInput2' type='search' placeholder='搜索' style={{ backgroundColor: 'transparent', border: 'none', color: '#333', flex: 1 }} />
                    </form>
                    <div onClick={() => { back() }} style={{ display: 'flex', alignItems: 'center' }}><Typography variant="subtitle2" style={{ color: '#333' }}>取消</Typography></div>
                </div>
            </div>
            <div className={['body'].join(' ')}>
                {lishi.length > 0 &&
                    <div style={{ marginTop: 10, padding: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 700, marginBottom: 14 }}>
                            <Typography variant="body2" style={{ fontWeight: 700 }}>搜索记录</Typography>
                            <div style={{ display: 'flex' }} onClick={() => { clearHistory() }}>
                                <DeleteForeverIcon fontSize='small' style={{ color: theme.palette.error.main }} /><Typography style={{ color: theme.palette.error.main, fontWeight: 700 }} variant="body2">清空</Typography>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {lishi.map((item, index) => <Typography onClick={() => { lishiItemClick(item) }} style={{ width: 100, textAlign: 'left', padding: ' 0 0 5px 5px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} variant="body2">{item}</Typography>)}
                        </div>
                    </div>
                }
            </div>
        </Fragment>
    );
}