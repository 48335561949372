

export const SETWEIXINLOGINMSG = 'SETWEIXINLOGINMSG'; //设置微信登陆信息
export const LOGOUT = 'LOGOUT'; //注销

//设置微信登陆信息
export function setWinXinLoginMsg(Token) {
    return async (dispatch, getState) => {
        console.log('NewToken', Token)
        //let { loginToken, nickname, headimgurl, sex, province, city } = Token;
        //存储在本地
        localStorage.setItem('Token', JSON.stringify(Token));
        return dispatch({ type: SETWEIXINLOGINMSG, payload: { ...Token } });
    }
}

//初始化微信登陆信息
export function initWinXinLoginMsg() {
    return async (dispatch, getState) => {
        //从本地加载
        let localToken = localStorage.getItem('Token');
        if (localToken == null || localToken.length < 1) {
            //没有
            return;// dispatch({ type: LOAD_FROM_STORAGE_FAIL });
        }
        else {//有
            let Token = JSON.parse(localToken)
            console.log('localToken', Token)
            return dispatch({ type: SETWEIXINLOGINMSG, payload: { ...Token } });
        }
    }
}

export function logout() {
    return async (dispatch, getState) => {
        //删除本地存储
        try {
            localStorage.removeItem('Token');
        } catch (e) { console.log('logout err', e) }
        return dispatch({ type: LOGOUT, payload: {} });
    }
}