import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import logger from 'redux-logger'

import rootReducer from "./Reducer";
import { composeWithDevTools } from 'redux-devtools-extension'

export default function configureStore(preloadedState) {
    return createStore(
        rootReducer,
        preloadedState,
        composeWithDevTools(
            applyMiddleware(thunkMiddleware, logger)
        ),
    );
}