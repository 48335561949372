import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import Bottom from './Bottom';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SearchIcon from '@material-ui/icons/Search';

import { useTheme } from '@material-ui/core/styles';

import WinXinSDK from '../WinXinSDK';
import { WXAppID, WebUrl, Name, APIBaseUrl } from '../Global';
import ReactPullLoad2 from './ReactPullLoad2';
import { Get } from '../Request';

import wx from 'weixin-js-sdk'


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useParams
} from "react-router-dom";


function Index(props) {
    let { User } = props;
    const theme = useTheme();
    let history = useHistory();
    let { openid } = useParams();
    console.log('openid', openid);
    if (openid != undefined && openid.length > 0) {
        localStorage.setItem('shareOpenId', openid);

        if (!User.isLogin) {
            let oldurl = `/`;
            //let openId = localStorage.getItem('shareOpenId');
            //console.log('openId', openId);
            //授权微信openid登陆
            let SCOPE = 'snsapi_base';
            let REDIRECT_URI = APIBaseUrl + '/Login/WXLogin?oldurl=' + oldurl + '&popenId=' + (openid == null ? '' : openid);
            let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WXAppID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=code&scope=${SCOPE}&state=STATE#wechat_redirect`
            window.location.href = url;
        }
    }

    const itemClick = (id) => {
        //history.push('/detail');
        //使用location（传统方式）跳转页面，在手机微信App里面可以缓存前一页的状态和滚动条的位置
        window.location.href = `/detail/${id}`;
    }

    const searchClick = () => {
        history.push('/search');
    }

    const [type, setType] = useState(0);
    const [jindu, setJindu] = useState(0);
    const [weidu, setWeidu] = useState(0);
    const typeClick = (index) => {
        if (index == 1) {
            wx.getLocation({
                type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: (res) => {
                    var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                    var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                    //var speed = res.speed; // 速度，以米/每秒计
                    //var accuracy = res.accuracy; // 位置精度
                    setJindu(longitude);
                    setWeidu(latitude);
                    setType(index);
                }
            });
        }
        else
            setType(index);
    }

    return (
        <Fragment>
            <WinXinSDK
            // updateAppMessageShareData={(returnOpenId) => {
            //     let params = {
            //         title: '测试标题', // 分享标题
            //         desc: '测试标题测试标题', // 分享描述
            //         link: `${WebUrl}/sign/${returnOpenId}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            //         imgUrl: '', // 分享图标
            //     }
            //     return {
            //         ...params,
            //         success: function () {
            //             // 设置成功
            //             alert('updateAppMessageShareData  Success');
            //             console.log('updateAppMessageShareData  Success', params);
            //         }
            //     };
            // }}
            // updateTimelineShareData={(returnOpenId) => {
            //     let params = {
            //         title: '测试标题', // 分享标题
            //         link: `${WebUrl}/sign/${returnOpenId}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            //         imgUrl: '', // 分享图标
            //     }
            //     return {
            //         ...params,
            //         success: function () {
            //             // 设置成功
            //             console.log('updateTimelineShareData  Success', params);
            //         }
            //     };
            // }}
            />

            <div className='header'>
                <div style={{ display: 'flex', alignItems: 'center', backgroundColor: theme.palette.primary.main, padding: 10, color: theme.palette.getContrastText(theme.palette.primary.main) }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="subtitle2">同城</Typography><div style={{ width: 4 }}></div><LocationOnIcon fontSize='small' /></div>
                    <div onClick={() => { searchClick() }} style={{ flex: 1, display: 'flex', alignItems: 'center', backgroundColor: 'rgb(255 255 255 / 23%)', borderRadius: 50, padding: '5px', marginLeft: 12 }}>
                        <SearchIcon /><input className='searchInput' type='text' placeholder='搜索' style={{ backgroundColor: 'transparent', border: 'none', color: '#FFF', flex: 1 }} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: theme.palette.primary.main, padding: '5px 10px 5px 10px', color: theme.palette.getContrastText(theme.palette.primary.main) }}>
                    <div className='LanMu' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div onClick={() => typeClick(0)} className={type == 0 && 'Now'}><Typography variant={type == 0 ? 'subtitle1' : 'subtitle2'}>精选推荐</Typography></div>
                        <div onClick={() => typeClick(1)} className={type == 1 && 'Now'}><Typography variant={type == 1 ? 'subtitle1' : 'subtitle2'}>离我最近</Typography></div>
                        <div onClick={() => typeClick(2)} className={type == 2 && 'Now'}><Typography variant={type == 2 ? 'subtitle1' : 'subtitle2'}>销量最多</Typography></div>
                        <div onClick={() => typeClick(3)} className={type == 3 && 'Now'}><Typography variant={type == 3 ? 'subtitle1' : 'subtitle2'}>价格优先</Typography></div>
                        <div onClick={() => typeClick(4)} className={type == 4 && 'Now'}><Typography variant={type == 4 ? 'subtitle1' : 'subtitle2'}>即将下架</Typography></div>
                    </div>
                </div>
            </div>

            <ReactPullLoad2
                className={['body'].join(' ')} style={{ paddingTop: 92, paddingBottom: 56 }}
                params={type}
                loadData={(page) => {
                    if (type == 1)
                        return Get(`/Public/HomeProductList?page=${page}&type=${type}&jindu=${jindu}&weidu=${weidu}`);
                    else
                        return Get(`/Public/HomeProductList?page=${page}&type=${type}`);
                }}
            >
                {(data, setData) => {
                    return data.map((item, index) => {
                        return (
                            <div onClick={() => itemClick(item.sp_id)} style={{ padding: '10px', backgroundColor: '#FFF' }}>
                                <div style={{ position: 'relative' }}>
                                    <img style={{ width: '100%', height: '180px', borderRadius: 10 }} src={item.sp_images.split(',')[0] + '?x-oss-process=image/resize,m_fill,w_414,h_200,limit_0'} />
                                    <div style={{ position: 'absolute', bottom: 10, right: 10, backgroundColor: theme.palette.success.main, color: '#FFF', borderRadius: 3, padding: 2, }}>
                                        <Typography variant="body2">抢购中</Typography>
                                    </div>
                                </div>
                                <div style={{ padding: '0 8px' }}>
                                    <Typography variant="subtitle1">{item.sp_title}</Typography>
                                </div>
                                <div style={{ padding: '0 8px' }}>
                                    <Typography variant='caption' style={{ color: '#999', wordBreak: 'break-word' }}>{item.sp_subtitle}</Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', color: '#999', padding: '0 8px' }}>
                                    <Typography variant='h6' style={{ color: theme.palette.secondary.main }}>￥{item.sp_price}</Typography>&nbsp;&nbsp;<Typography variant='caption'>门市价：￥{item.sp_marketprice}</Typography>&nbsp;&nbsp;<Typography variant='caption'>销量 {item.sp_salescount}</Typography>
                                </div>
                            </div>
                        )
                    })
                }}
            </ReactPullLoad2>

            <Bottom chooseIndex={0} />
        </Fragment>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        User: state.User
    }
}

export default connect(
    mapStateToProps
)(Index);