import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ReactPullLoad2 from '../ReactPullLoad2';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import PeopleIcon from '@material-ui/icons/People';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

import { Get, Post } from '../../Request';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    cont: {
        paddingTop:199,
        flex: 1,
    }
}));


function Achievement(props) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const back = () => {
        history.goBack();
    }

    const [statistics, setStatistics] = useState({});
    useEffect(() => {
        Get(`/Member/Member/GetAchievementStatistics`).then((json) => {
            console.log('GetAchievementStatistics', json);
            if (json.Code == 1)
                setStatistics(json.Result);
        });
    }, []);

    return (
        <Fragment>
            <AppBar elevation={0} className='header'>
                <Toolbar>
                    <IconButton onClick={() => back()} edge="start" color="inherit" >
                        <ChevronLeftIcon fontSize="large" />
                    </IconButton>
                    <Typography variant="subtitle1" >
                        我的佣金
                </Typography>
                </Toolbar>
                <div style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.getContrastText(theme.palette.primary.main) }}>
                    <div style={{ padding: '20px 0', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <div style={{ textAlign: 'center' }}>
                            <Typography variant="h6">总共 {statistics.achievementTotal || '--'}</Typography>
                            <Typography variant="caption">({statistics.achievementCount || '--'})</Typography>
                        </div>
                        <div>
                            <div style={{ textAlign: 'center' }}>
                                <Typography variant="subtitle1">本月 {statistics.achievementThisMouthTotal || '--'}</Typography>
                                <Typography variant="caption">({statistics.achievementThisMouthCount || '--'})</Typography>
                            </div>
                            <div style={{ height: 4 }}></div>
                            <div style={{ textAlign: 'center' }}>
                                <Typography variant="subtitle1">本周 {statistics.achievementThisWeekTotal || '--'}</Typography>
                                <Typography variant="caption">({statistics.achievementThisWeekCount || '--'})</Typography>
                            </div>
                        </div>
                        <div>
                            <div style={{ textAlign: 'center' }}>
                                <Typography variant="subtitle1">昨天 {statistics.achievementYesterdayTotal || '--'}</Typography>
                                <Typography variant="caption">({statistics.achievementYesterdayCount || '--'})</Typography>
                            </div>
                            <div style={{ height: 4 }}></div>
                            <div style={{ textAlign: 'center' }}>
                                <Typography variant="subtitle1">今天 {statistics.achievementTodayTotal || '--'}</Typography>
                                <Typography variant="caption">({statistics.achievementTodayCount || '--'})</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </AppBar>

            <ReactPullLoad2
                className={['body', classes.cont].join(' ')}
                loadData={(page) => {
                    return Get(`/Member/Member/GetAchievementList?page=${page}`);
                }}

            >
                {(data, setData) => (
                    <List>
                        {data.map((item, index) => {
                            return (
                                <ListItem onClick={() => {
                                    //itemClick(item)
                                }}>
                                    <ListItemAvatar >
                                        <Avatar style={{ width: 45, height: 45 }} src={item.MemberHeadImgUrl}>
                                            <AccountCircleIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText className={classes.ListItemText} primary={item.MemberNickName} secondary={<React.Fragment>
                                        <div>
                                            <Typography variant="caption">{'在' + item.StoreCity + item.StoreShortName + '消费￥' + item.payerTotal}</Typography>
                                        </div>
                                        <Typography variant="caption">{item.orderCreateTime}</Typography>
                                    </React.Fragment>} />
                                    <ListItemSecondaryAction style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                        {/* <ChevronRightIcon /> */}
                                        <Typography variant="caption">{item.Status}</Typography>
                                        <Typography variant="caption">{item.CommissionPercentage * 100 + '%'}</Typography>
                                        <Typography variant="caption">￥{item.CommissionPrice}</Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                )}
            </ReactPullLoad2>
        </Fragment>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        User: state.User
    }
}

export default connect(
    mapStateToProps
)(Achievement);