import { combineReducers } from "redux";

import { SETWEIXINLOGINMSG, LOGOUT } from './Action';

const initialState = {
    isLogin: false,
    loginToken: '',
    nickname: '未登录，点击登陆',
    openid: '',
    headimgurl: '',
    sex: 0,
    province: '未知',
    city: '未知',
};

function User(state = initialState, action) {
    switch (action.type) {

        case SETWEIXINLOGINMSG:
            return { ...state, isLogin: true, loginToken: action.payload.loginToken, nickname: action.payload.nickname, openid: action.payload.openid, headimgurl: action.payload.headimgurl, sex: action.payload.sex, province: action.payload.province, city: action.payload.city };

        case LOGOUT:
            return { isLogin: false, loginToken: '', nickname: '未登录，点击登陆', openid: '', headimgurl: '', sex: 0, province: '未知', city: '未知', };

        default:
            return state;
    }
}


//使用combineReducers集成所有的reducer
export default combineReducers({
    User
});