import React from 'react';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import AssistantIcon from '@material-ui/icons/Assistant';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
} from "react-router-dom";

export default function Bottom(props) {

    console.log('Bottom-props', props);


    let history = useHistory();
    let { chooseIndex } = props;

    function toHomeIndex() {
        //console.log('history', history)
        history.push('/')
    }

    function toMeIndex() {
        history.push('/meindex');
    }

    return (
        <BottomNavigation
            className='footer'
            value={chooseIndex}
            //onChange={(event, newValue) => {
            //    setValue(newValue);
            //}}
            showLabels
            
        >
            <BottomNavigationAction label='精选推荐' icon={<AssistantIcon />} onClick={toHomeIndex} />
            <BottomNavigationAction label='我的' icon={<AccountBoxIcon />} onClick={toMeIndex} />

        </BottomNavigation>
    );
}