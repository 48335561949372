import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ReactPullLoad2 from '../ReactPullLoad2';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import PeopleIcon from '@material-ui/icons/People';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

import { Get, Post } from '../../Request';
import { Box } from '@material-ui/core';
import { APIBaseUrl, WXAppID, WebUrl, Name } from '../../Global';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: 'flex', flexDirection: 'column'
    },
    cont: {
        flex: 1,
    }
}));


function Redpack(props) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const back = () => {
        history.goBack();
    }

    return (
        <Fragment>
            <AppBar elevation={0} className='header'>
                <Toolbar>
                    <IconButton onClick={() => back()} edge="start" color="inherit" >
                        <ChevronLeftIcon fontSize="large" />
                    </IconButton>
                    <Typography variant="subtitle1" >
                        红包记录
                    </Typography>
                </Toolbar>
            </AppBar>
            <ReactPullLoad2
                className={['body', classes.cont].join(' ')}
                loadData={(page) => {
                    return Get(`/Member/Member/GetRedpackList?page=${page}`);
                }}

            >
                {(data, setData) => (
                    <List>
                        {data.map((item, index) => {
                            return (
                                <ListItem onClick={() => {
                                    let url = `/redpackpage/${item.orderNo}/sign/${item.memberOpenId}`;
                                    window.location.href = url;
                                }}>
                                    <ListItemAvatar >
                                        <img style={{ width: 45, height: 45 }} src={`${WebUrl}/redpack.jpg`} />
                                    </ListItemAvatar>
                                    <ListItemText className={classes.ListItemText} primary={item.remark} secondary={item.orderCreateTime} />
                                    <ListItemSecondaryAction>
                                        <div><Typography variant="title">￥{item.payTotal}</Typography></div>
                                        <Typography variant="title">{item.robbedCount + '/' + item.count}</Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                )}
            </ReactPullLoad2>
        </Fragment>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        User: state.User
    }
}

export default connect(
    mapStateToProps
)(Redpack);