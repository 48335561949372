import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';


import Avatar from '@material-ui/core/Avatar';

import MailOutlineIcon from '@material-ui/icons/MailOutline';

import ReactPullLoad2 from '../ReactPullLoad2';
import { APIBaseUrl } from '../../Global';
import { compareAsc, format } from 'date-fns'
import axios from 'axios';

import { Get, Post } from '../../Request';


const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1, display: 'flex', flexDirection: 'column',
        position: 'fixed', //这个很重要，不然会和ios的下拉有冲突
        top: 0, left: 0, right: 0, bottom: 0,
    },
    cont: {
        flex: 1, padding: '66px 10px 10px 10px', overflow: 'auto'
    }
}));

function MessageDetail(props) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const { data, handleClose } = props;

    useEffect(() => {
        if (!data.Read) {
            //修改Read
            console.log('修改Read')
            let params = { id: data.id };
            Post(`/Member/Member/ChangeMemberMessageRead`, { data: params }).then((json) => {
                console.log('ChangeMemberMessageRead', json);
            });
        }
    });
    return (
        <div className={classes.root}>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton onClick={() => handleClose && handleClose()} edge="start" color="inherit" >
                        <CloseIcon fontSize="large" />
                    </IconButton>
                    <Typography variant="subtitle1" >
                        消息
                    </Typography>
                </Toolbar>
            </AppBar>

            {data == null ?
                <div className={classes.cont}>

                    <Typography variant="h3" gutterBottom>
                        null
                    </Typography>
                </div>
                :
                <div className={classes.cont}>
                    <Typography variant="h6" gutterBottom>
                        {data.Title}
                    </Typography>

                    <Typography variant="caption" display="block" gutterBottom>
                        {format(new Date(data.CreateTime), 'yyyy-MM-dd HH:mm:ss')}
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                        {data.Content}
                    </Typography>
                </div>
            }

            {/* <ReactPullLoad2
                isBlockContainer={true}
                className={classes.cont}
                loadData={(page) => {
                    return Get(`/Member/Member/GetMemberMessageList?page=${page}`);
                }}
            >
                {(data) => (
                    <List>
                        {data.map((item, index) => {
                            return (
                                <ListItem >
                                    <ListItemAvatar >
                                        <Avatar style={{ backgroundColor: item.Read ? theme.palette.grey[400] : theme.palette.primary.main }}>
                                            <MailOutlineIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={item.Title} secondary={<React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            style={{ color: theme.palette.grey[400], }}
                                        >
                                            {format(new Date(item.CreateTime), 'MM-dd HH:mm')}
                                        </Typography>
                                        &nbsp;
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            style={{ color: theme.palette.grey[500], }}
                                        >
                                            {item.Content}
                                        </Typography>
                                    </React.Fragment>} />
                                    <ListItemSecondaryAction>
                                        <ChevronRightIcon />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                )}
            </ReactPullLoad2> */}
        </div>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        User: state.User
    }
}

export default connect(
    mapStateToProps
)(MessageDetail);