import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Get, Post } from '../../Request';

const useStyles = makeStyles((theme) => ({
    cont: {
        display: 'flex', justifyContent: 'center', alignItems: 'center'
    }
}));


function MyShare(props) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const back = () => {
        history.goBack();
    }

    const [info, setInfo] = useState({ Erweima: '' });
    useEffect(() => {
        Get(`/Member/Member/GetMyShare`).then((json) => {
            console.log('GetMyShare', json);
            if (json.Code == 1)
                setInfo(json.Result);
        });
    }, []);

    return (
        <Fragment>
            <AppBar className='header'>
                <Toolbar>
                    <IconButton onClick={() => back()} edge="start" color="inherit" >
                        <ChevronLeftIcon fontSize="large" />
                    </IconButton>
                    <Typography variant="subtitle1" >
                        我要圈粉
                </Typography>
                </Toolbar>
            </AppBar>
            <div className={['body', classes.cont].join(' ')}>
                {info.Erweima.length < 1 ? <div>加载中...</div> :
                    <img src={info.Erweima} style={{ width: '60%' }} />
                }
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        User: state.User
    }
}

export default connect(
    mapStateToProps
)(MyShare);