/*global WeixinJSBridge*/
import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { APIBaseUrl, WXAppID, WebUrl, Name } from '../../Global';

import { Get, Post } from '../../Request';

const useStyles = makeStyles((theme) => ({
    cont: {
        display: 'flex', flexDirection: 'column', alignItems: 'center'
    }
}));


function CreateRedpack(props) {
    let { User } = props;
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const isRealLogin = () => {
        return User.isLogin && (User.nickname != User.openid);
    }

    if (!isRealLogin()) {
        window.location.href = '/meindex';
    }

    const back = () => {
        history.goBack();
    }

    let [value, setValue] = useState('');
    const onChange = (event) => {
        let val = event.target.value.trim();
        setValue(val);
    }

    let [count, setCount] = useState('10');
    const onCountChange = (event) => {
        let val = event.target.value.trim();
        setCount(val);
    }

    let [remark, setRemark] = useState('恭喜发财 大吉大利');
    const onRemarkChange = (event) => {
        let val = event.target.value.trim();
        setRemark(val);
    }

    const getPrice = () => {
        let price = parseFloat(value.trim());
        if (isNaN(price)) {
            price = 0;
        }
        return price.toFixed(2).toString();
    }

    const Confirm = () => {
        console.log('value', value.trim());
        let price = parseFloat(value.trim());
        if (isNaN(price)) {
            alert('请输入正确的金额'); return;
        }

        console.log('count', count.trim());
        let hb_count = parseFloat(count.trim());
        if (isNaN(hb_count)) {
            alert('请输入正确的红包个数'); return;
        }

        price = price.toFixed(2);
        setValue(price.toString());//修正金额

        //发起支付
        Post('/WXRedpackPublic/CreateRedpack', { data: { price, count: hb_count, remark } })
            .then(json => {
                if (json.Code == 1) {
                    let { timeStamp, nonceStr, signType, paySign, orderNo, openId } = json.Result;
                    let can = {
                        "appId": WXAppID,        //公众号ID，由商户传入     
                        "timeStamp": timeStamp,        //时间戳，自1970年以来的秒数     
                        "nonceStr": nonceStr,       //随机串     
                        "package": json.Result.package,
                        "signType": signType,       //微信签名方式：     
                        "paySign": paySign //微信签名 
                    };
                    WeixinJSBridge.invoke('getBrandWCPayRequest', can,
                        function (res) {
                            if (res.err_msg == "get_brand_wcpay_request:ok") {
                                // 使用以上方式判断前端返回,微信团队郑重提示：
                                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                console.log('支付成功了', 'res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠');
                                window.location.href = `/redpackpage/${orderNo}/sign/${openId}`;
                            } else {
                                alert('取消了支付')
                            }
                        });
                    /*  wx.chooseWXPay({
                         appId: WXAppID,
                         timestamp: timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                         nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
                         package: json.Result.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                         signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                         paySign: paySign, // 支付签名
                         success: function (res) {
                             // 支付成功后的回调函数
                             window.location.href = '/';
                         },
                         cancel: function () {
                             alert('取消了支付')
                         }
                     }); */
                }
                else {
                    alert(json.Message)
                    //window.location.href = '/meindex';
                }
            }
            ).catch(err => {
                alert(err);
            });

    }

    return (
        <Fragment>
            <AppBar className='header'>
                <Toolbar>
                    <IconButton onClick={() => back()} edge="start" color="inherit" >
                        <ChevronLeftIcon fontSize="large" />
                    </IconButton>
                    <Typography variant="subtitle1" style={{ flex: 1 }} >
                        我要圈粉（红包圈粉）
                    </Typography>
                    <Button onClick={() => { history.push('/me/redpack'); }} style={{ color: theme.palette.getContrastText(theme.palette.primary.main) }}>红包记录</Button>
                </Toolbar>
            </AppBar>
            <div className={['body', classes.cont].join(' ')}>
                <div style={{ height: 30 }}></div>
                <div style={{ display: "flex", flexDirection: 'column', width: '80%' }}>
                    <div style={{ fontSize: 13 }}>请输入红包金额</div>
                    <input type='text' value={value} onChange={(e) => onChange(e)} placeholder='金额' style={{
                        height: 50,
                        borderColor: theme.palette.grey[500], borderWidth: 1, borderStyle: 'solid',
                        borderRadius: 4, padding: '0 10px',
                        fontSize: 18
                    }} />
                </div>
                <div style={{ height: 12 }}></div>

                <div style={{ display: "flex", flexDirection: 'column', width: '80%' }}>
                    <div style={{ fontSize: 13 }}>请输入红包个数</div>
                    <input type='text' value={count} onChange={(e) => onCountChange(e)} placeholder='个数' style={{
                        height: 50,
                        borderColor: theme.palette.grey[500], borderWidth: 1, borderStyle: 'solid',
                        borderRadius: 4, padding: '0 10px',
                        fontSize: 18
                    }} />
                </div>
                <div style={{ height: 12 }}></div>

                <div style={{ display: "flex", flexDirection: 'column', width: '80%' }}>
                    <div style={{ fontSize: 13 }}>请输入祝福语</div>
                    <input type='text' value={remark} onChange={(e) => onRemarkChange(e)} placeholder='祝福语' style={{
                        height: 50,
                        borderColor: theme.palette.grey[500], borderWidth: 1, borderStyle: 'solid',
                        borderRadius: 4, padding: '0 10px',
                        fontSize: 18
                    }} />
                </div>
                <div style={{ height: 30 }}></div>
                <div style={{ fontSize: 26, fontWeight: 'bold' }}>￥<span style={{ fontSize: 45 }}>{getPrice()}</span></div>

                <Button onClick={() => Confirm()} variant="contained" color="primary" style={{ marginTop: 20, height: 45, padding: '10px 45px', borderRadius: 4, backgroundColor: getPrice() > 0 ? '#ff3600' : '#ff36008f' }}>
                    塞钱进红包
                </Button>

                <Typography variant="caption" style={{ width: '80%', marginTop: 26, color: "#9e9e9e" }} >
                    系统设定红包为48小时未领取，红包剩余未领取金额失效；但是红包功能依然有效，凡是访客首次点击打开，还是可以圈粉
                </Typography>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        User: state.User
    }
}

export default connect(
    mapStateToProps
)(CreateRedpack);